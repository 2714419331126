// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-club-info-tsx": () => import("./../../../src/pages/club-info.tsx" /* webpackChunkName: "component---src-pages-club-info-tsx" */),
  "component---src-pages-committee-tsx": () => import("./../../../src/pages/committee.tsx" /* webpackChunkName: "component---src-pages-committee-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-history-tsx": () => import("./../../../src/pages/history.tsx" /* webpackChunkName: "component---src-pages-history-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-membership-tsx": () => import("./../../../src/pages/membership.tsx" /* webpackChunkName: "component---src-pages-membership-tsx" */),
  "component---src-pages-race-timer-tsx": () => import("./../../../src/pages/race-timer.tsx" /* webpackChunkName: "component---src-pages-race-timer-tsx" */),
  "component---src-pages-training-tsx": () => import("./../../../src/pages/training.tsx" /* webpackChunkName: "component---src-pages-training-tsx" */),
  "component---src-pages-venues-tsx": () => import("./../../../src/pages/venues.tsx" /* webpackChunkName: "component---src-pages-venues-tsx" */),
  "component---src-templates-club-event-tsx": () => import("./../../../src/templates/club-event.tsx" /* webpackChunkName: "component---src-templates-club-event-tsx" */)
}

